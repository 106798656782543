<template>
  <div class="row">
    <div class="col-12 mt-2">
      <div class="modal-body">
        <el-form
          class="process-form"
          ref="form"
          status-icon
          :model="form"
          :rules="rules"
          :label-position="'left'"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <div>{{ $t("message.order") }}</div>
              <el-input
                size="medium"
                v-model="form.order_id"
                :placeholder="$t('message.order_id')"
              >
              </el-input>
            </el-col>
            <el-col :span="12">
              <div>{{ $t("message.service") }}</div>
              <select-order-service
                size="medium"
                :service_id="form.service_id"
                @parent-event="parentEvent"
                v-model="form.service_id"
                :order_id="form.order_id"
              ></select-order-service>
            </el-col>
          </el-row>

          <ul class="pratses" v-for="process in form_processes" :key="process">
            <li class="m-3">
              <span>{{ process.name }}</span>
              <users
                class="mx-2"
                size="medium"
                v-model="process.doctor_id"
                :doctor_id="process.doctor_id"
              ></users>
              <span>
                {{
                  process.service
                    ? process.service.amount_type == "percent"
                      ? (100 + process.service.amount) *
                        process.service_model.price
                      : process.service.amount
                    : 0
                }}
                sum
              </span>
            </li>
          </ul>
        </el-form>
        <div class="add-btn--p">
          <el-button v-if="is_last" @click="submit()" type="success">
            {{ $t("message.save_and_create_new") }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selectOrderService from "@/components/inventory-select/select-order-service";
import drawerChild from "@/utils/mixins/drawer-child";
import users from "@/components/selects/user_list";
import form from "@/utils/mixins/form";
export default {
  mixins: [form, drawerChild],
  props: ["is_last"],
  data() {
    return {
      form_processes: [],
      processesShow: false,
    };
  },
  components: {
    selectOrderService,
    users,
  },
  created() {
    console.log(this.is_last);
  },
  computed: {
    ...mapGetters({
      rules: "dailyProcessReport/rules",
      model: "dailyProcessReport/model",
      columns: "dailyProcessReport/columns",
      processes: "processes/list",
    }),
  },
  async created() {
    if (this.processes && this.processes.length === 0) await this.loadProcess();
    await this.get();
  },
  updated() {
    if (this.is_last) {
      this.loadProcess();
    }
  },

  methods: {
    get() {
      this.form_processes = [];
      this.form_processes = this.processes;
    },
    ...mapActions({
      save: "dailyProcessReport/store",
      loadProcess: "processes/index",
      empty: "dailyProcessReport/empty",
    }),
    parentEvent(value) {
      const query = { service_id: value };
      this.loadProcess(query).then((res) => {
        this.form_processes = res.data.processes.data;
      });
    },
    submit(close = true) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          this.form.processes = this.form_processes.filter(
            (val) => val.doctor_id != null
          );
          var amount = 0;
          this.form.processes.forEach((val) => {
            if (val.service != null) {
              amount +=
                val.service.amount_type == "percent"
                  ? (100 + val.service.amount) * val.service_model.price
                  : val.service.amount;
            }
          });

          this.form.amount = amount;
          this.save(this.form)

            .then((res) => {
              this.$emit("parent-event", 1);
              this.loadingButton = false;
              this.$alert(res);
              // this.form_processes.forEach((val) => {
              //   val.doctor_id = null;
              // });
              this.resetForm("form");
              //   if (close == true) {
              //   this.close();
              //   }
            })
            .catch((err) => {
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    afterOpened() {
      this.form = JSON.parse(JSON.stringify(this.model));
    },
    afterClosed() {
      this.empty();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.$emit("c-close", { drawer: "drawerCreate" });
    },
  },
};
</script>
<style lang="scss" scoped>
.pratses {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.process-form {
  background: #f7f7f7;
  padding: 5px 20px;
  margin-bottom: 10px;
  border-radius: 4px;
}
.add-btn--p {
  text-align: center;
}
</style>